<template>
<div class="gen-section-padding">
 <vueper-slides 
    autoplay
    rtl
    class="no-shadow"
    :visible-slides="4"
    slide-multiple
    :gap="3"
    :slide-ratio="1 / 5"
    :dragging-distance="200"
    :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
    <vueper-slide class="gen-movie-img" v-for="publish in carousel" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="getFeed(publish)" />
    <!-- <vueper-slide class="gen-movie-img" v-for="publish in carousel.slice(8,12)" :key="publish.id" :image="publish.carsoul_img" :title="publish.title_ar" @click="getFeed(publish)" /> -->
  </vueper-slides>
</div>
<!-- Videos Style 1 -->
    <section class="gen-section-padding-3 upper_margin">
        <div class="pc-tab">
            <input checked="checked" id="tab0" type="radio" name="pct" />
            <template v-for="publish in categoris" :key="publish.id">
                <input :id="publish.tab" type="radio" name="pct"/>
            </template>
    <nav>
      <ul  class="slider-radio justify">
        <li class="tab0">
          <label for="tab0"><SVGHome/>كل المحتوي</label>
        </li>
        <template v-for="publish in categoris" :key="publish.id">
          <li :class="publish.tab" @click="gettab(publish)"><label :for="publish.tab"><SVGHome/>{{ publish.name_ar }}</label></li>
        </template>
      </ul>
    </nav>
    <section style="padding: 0 0px 130px 0px;">
    <div class="tab0">
      <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in content" :key="publish.id">
                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                <div class="gen-movie-contain" @click="getFeed(publish)">
                                  <div class="gen-movie-img" >
                                    <a  @click="getFeed(publish)" >
                                        <img v-lazy="publish.carsoul_img" alt="single-video-image" style="max-height: 280px;">
                                        <div class="gen-movie-action">
                                            <SVGPlay/>
                                        </div>
                                    </a>
                                    <div class="gen-info-contain">
                                        <div class="gen-movie-info">
                                            <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                        </div>
                                        <div class="gen-movie-meta-holder">
                                            <ul>
                                                <li>{{publish.year}}</li>
                                                <li><a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <template v-for="publish in categoris" :key="publish.id">
      <div :class="publish.tab">
      <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in cateData" :key="publish.id">
                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                <div class="gen-movie-contain" @click="getFeed(publish)">
                                  <div class="gen-movie-img" >
                                    <a  @click="getFeed(publish)" >
                                        <img v-lazy="publish.carsoul_img" alt="single-video-image" style="max-height: 280px;">
                                        <div class="gen-movie-action">
                                            <SVGPlay/>
                                        </div>
                                        </a>
                                        <div class="gen-info-contain">
                                        <div class="gen-movie-info">
                                            <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                        </div>
                                        <div class="gen-movie-meta-holder">
                                            <ul>
                                                <li>{{publish.year}}</li>
                                                <li>
                                                    <a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      </template>
    </section>
  </div>
        
    </section>
</template>
<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import $ from 'jquery'
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from "vue-toastification";
import SVGHome from '../SVG/SVGHome.vue';
import SVGPlay from "../SVG/SVGPlay.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
   name: 'AppHome',
    components: { 
      SVGHome,
      SVGPlay,
      VueperSlides, 
      VueperSlide
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const toast = useToast();
      const carousel = ref([]);
      const content = ref([]);
      const categoris = ref([]);
      const cateData = ref([]);

      watchEffect(() => {
        if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
          let urlParams = new URLSearchParams(window.location.search);
          if(urlParams.has('msisdn')) {
            HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                if (res.data.status == 1 ) {
                    cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                    toast.success("مرحبا بك معنا  ");
                    if(cookie.getCookie("content_id")!=null){
                      toast.info("سوف يتم تحويلك الي المحتوي",);
                      setTimeout(() =>  router.push({name: "Contents", params: { id: cookie.getCookie("content_id") } }), 2000);
                    } else {
                      setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                } else if (res.data.status == 0 ) {
                  cookie.removeCookie('msisdn');
                    toast.error("لست مشترك في هذة الخدمة",);
                    window.open("http://landingpage.sd.zain.com/?p=2416749956", "_self");

                    // setTimeout(() => router.push({ path: "/" }), 2500);
                }
                
                })
            }
        }
      });

      try {
         HTTP.get('GetCarousel.php?LIMIT=12').then((res) => {
            carousel.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
        
      try {
         HTTP.get('GetAllContent.php?LIMIT=30&OFFSET=0').then((res) => {
            content.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
          HTTP.get(`GetAllCategories.php`).then((res) => {
            categoris.value = res.data.Categories; 
          });
      } catch (err) {
           console.log(err);
      }

      const gettab = (publish) => {
          HTTP.get(`GetContentByCategory.php?LIMIT=50&OFFSET=0&cat_id=` + publish.id).then((res) => {
            cateData.value = res.data.Content; 
        });
      }
     
      const getFeed = (publish) => {
        cookie.setCookie('cat_id', publish.cat_id);
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status") && cookie.isCookieAvailable("remming_minutes") > 0) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        // } else {
        //   cookie.setCookie('content_id', publish.id);
        //   router.push({ name: "Subscribes" });
        // }
      };
      return { carousel,content, getFeed,gettab,categoris, cateData };
   },
   mounted() {
   }
}
</script>


<style>
.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#65a830
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
background: #00000096;;
height: 25%;
bottom: 0px;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 16px;
color: #fff;
z-index: 999;
border-radius: 10px;
flex-direction: column;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 42vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 35vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#000000b3
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}

.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
     user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#004d4d;
}



.pointer {
  cursor: pointer;
}
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,700);
/* Component Needs */
.pc-tab > input,
.pc-tab section > div {
  display: none;
}
#tab0:checked ~ section .tab0,
#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  display: block;
}
#tab0:checked ~ nav .tab0,
#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  color: red;
}
/* Visual Styles */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: #e74c3c;
}
.pc-tab {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin: 0 auto;
}
.pc-tab ul {
  list-style: none;
justify-content: center;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
padding-right: 0%;
padding-left: 0%;
}
.pc-tab ul li label {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 43px;
padding: 0 10px;
font-size: 18px;
font-weight: 600;
font-family: "Cairo", sans-serif;
color: #fff;
background: #016868;
margin-bottom: 0;
cursor: pointer;
border-radius: 0;
margin: 0 0;
position: relative;
z-index: 100;
transition: color 0.5s;
border-left: 1px solid #0000006b;
}
.pc-tab ul li label:hover {
  background-color: #06c998;
}
.pc-tab ul li label:active {
  background-color: #06c998;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  font-family: "Droid Serif";
  clear: both;
}

.pc-tab section div h2 {
  margin: 0;
  font-family: "Raleway";
  letter-spacing: 1px;
  color: #34495e;
}
#tab0:checked ~ nav .tab0 label,
#tab1:checked ~ nav .tab1 label,
#tab2:checked ~ nav .tab2 label,
#tab3:checked ~ nav .tab3 label,
#tab4:checked ~ nav .tab4 label,
#tab5:checked ~ nav .tab5 label,
#tab6:checked ~ nav .tab6 label {
  color: #1d3544;
  background-color: #2bac8c;
  position: relative;
}
#tab0:checked ~ nav .tab0 label:after,
#tab1:checked ~ nav .tab1 label:after,
#tab2:checked ~ nav .tab2 label:after,
#tab3:checked ~ nav .tab3 label:after,
#tab4:checked ~ nav .tab4 label:after,
#tab5:checked ~ nav .tab5 label:after,
#tab6:checked ~ nav .tab6 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: transparent;
  right: 0;
  bottom: -1px;
}



  /* @media (min-width: 412px) {
    .shotal {
       padding: 15% 3%;
    }
  } */



</style>